module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-source-wordpress/gatsby-browser.js'),
      options: {"plugins":[],"url":"https://pretparken.api.kameleon-media.be/graphql","verbose":true,"catchLinks":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-matomo/gatsby-browser.js'),
      options: {"plugins":[],"siteId":"1","matomoUrl":"https://dashboard.kameleon-media.be","siteUrl":"https://pretparkvoordeel.be"},
    },{
      plugin: require('../node_modules/gatsby-plugin-cookiehub/gatsby-browser.js'),
      options: {"plugins":[],"cookihubId":"829536eb","trackingId":"","head":false,"anonymize":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Pretparkvoordeel.be","short_name":"Pretparkvoordeel","description":"We verzamelden alle kortingen en promos in pretparken voor je","start_url":"/","background_color":"#ff2b56","theme_color":"#fff","display":"standalone","icon":"src/img/pretpark-logo-sq.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"eb50cb0ed11d31a173e735a668403158"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
